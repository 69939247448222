import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import FullImage from '../../components/inner/venderProduct/FullImage'

import UnionImage from '../../assets/technologies/Union1.png'
import COLOR from '../../global/Color'

import UnionImage02 from '../../assets/technologies/unionImage02.png'



const Mobile = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Union Image </HeaderTitle>

        <div style={{height: '60px'}}></div>

        <HeaderContentSmall>
          Shenzhen United Imaging Co., Ltd. <a href="https://en.unimage.com.cn" target="_blank" style={{color: COLOR.BLUE}}>(Union Image - unimage.com.cn)</a>는 2014년에 설립된 카메라 모듈, 시각 솔루션 및 이미지 기계의 연구개발(R&D), 제조 및 서비스를 통합하는 하이테크 기업입니다. <br></br>
          회사의 지주 자회사는 Shenzhen United Optical Technology Co., Ltd.(이하 "United Optics")와 Weihai United Imaging Co., Ltd.(이하 "Weihai Imaging")입니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          United Optics는 주로 사물 인터넷(AIoT) 및 AI 비전 알고리즘 솔루션 분야에 종사하며, Weihai Imaging은 주로 휴대폰 카메라 모듈 분야에 종사하고 있습니다.
        </HeaderContentSmall>

        <FullImage Item={UnionImage} />

        <SubHeading>비전과 전문성</SubHeading>
        <HeaderContentSmall>
          Shenzhen United Imaging은 심천에 위치한 첨단 R&D 및 제조 센터를 기반으로 고객에게 맞춤형 솔루션과 서비스를 제공하며,<br></br>
          완벽한 시각적 리소스 라이브러리를 축적하여 수천 개의 산업에 힘을 실어주는 세계 최고의 AIoT 비전 솔루션 제공업체가 되고자 합니다.
        </HeaderContentSmall>

        <div style={{height: 50}} />

        <SubHeading>주요 제품 및 서비스</SubHeading>
        <HeaderContentSmall>
          Shenzhen United Imaging은 AIoT 시장에서 높은 커버리지율을 자랑하며, 고객이 가능한 한 최단 시간에 제품을 시장에 출시할 수 있도록 실시간 기술 지원과 전문 기술 지원을 제공합니다. <br></br>
          회사는 고객과의 윈-윈 상황을 창출하며, 전 세계적으로 경쟁력을 유지하고 있습니다.
        </HeaderContentSmall>

        <FullImage Item={UnionImage02}/>

      </HeaderContainer>

    </>
  )
}

export default Mobile