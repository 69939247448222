import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'

import GoodixTableBox from '../../components/inner/venderProduct/GoodixTableBox'
import GoodixTable from '../../components/inner/venderProduct/GoodixTable'
import FullImage from '../../components/inner/venderProduct/FullImage'

import AibusImage from '../../assets/technologies/Aibus.png'
import styled from 'styled-components'
import DivideLine from '../../components/inner/venderProduct/DivideLine'
import SIZE from '../../global/Size'
import AiverseTable from '../../components/inner/venderProduct/AiverseTable'

const GoodixTableItem01 = [
  {
    title: '정밀한 충방전 제어',
    text: [(<>
      고정밀 충방전 제어로 배터리 셀의 성능을 최적화합니다.
    </>)]
  },
  {
    title: '고속 처리 및 대량 생산 가능',
    text: [(<>
        고속 처리를 통해 대량 생산이 가능하여 효율성을 극대화합니다.
    </>)]
  },
  {
    title: '우수한 데이터 관리 및 모니터링 시스템',
    text: [(<>
        배터리 성능을 실시간으로 모니터링하고 데이터를 효율적으로 관리할 수 있는 시스템을 제공합니다.
    </>)]
  },
  {
    title: '에너지 효율적인 설계',
    text: [(<>
      충/방전 운용은 모두 스위칭 모드로 동작하여 에너지 효율을 높이고, 방전 시에는 전력 회생 구조를 통해 비용 효율성을 극대화합니다.
    </>)]
  },
  {
    title: '넓은 온도 범위에서 안정적 운영',
    text: [(<>
      다양한 온도 조건에서도 안정적으로 작동하여 배터리 셀의 성능을 유지합니다.
    </>)]
  },
  {
    title: '다양한 셀 타입 지원',
    text: [(<>
      Li-Ion 배터리를 포함한 다양한 배터리 타입을 지원하여 유연성을 제공합니다.
    </>)]
  },
  {
    title: '높은 신뢰성과 내구성',
    text: [(<>
      장기간의 사용에도 높은 신뢰성과 내구성을 보장합니다.
    </>)]
  },
]

const CapacitiveHeaderData = ["Feature", ""];
const CapacitiveRowData = [
  ["Input Power Supply","3 Phase 380Vac 50/60Hz"],
  ["Charging Voltage", "2.5~5V(4.2V@battery contact)"],
  ["Charging Current", "60A/70A/100A/200A/300A/400A"],
  ["Voltage Accuracy", "+/-0.05% @F.S (@23℃)"],
  ["Current  Accuracy", "+/-0.1% @F.S (@23℃)"],
  ["Current Rising time", "<20ms"],
  ["Control Mode", "CC, CV, OCV, CP, CR, CP/CV, Simulation"],
  ["Voltage Resolution", "16 bit"],
  ["Current Resolution", "16 bit"],
  ["Control Interval", "1000ms"],
  ["EMS Interface", "Ethernet (RJ-45/Cat-5e)"],
  ["Jig Interface", "Serial Com. (RS-485,RS-232 Opt)"],
  ["Cal. Interface", "Serial Com. (RS-485,RS-232 Opt)"],
  ["EMS", "PC GUI"],
];

const PerformanceHeaderData = ["Performance Management", ""];
const PerformanceRowData = [
  ["Basic Performance Management", "Current, Voltage, Power, Capacity, Energy, Elapse Time"],
  ["Safety Condition", "Over Voltage Protection, Over Current Protection, Over Temperature Protection, Over Charging/Discharging, Temp. Sensor, FAN Alarm, Battery Reverse Polarity"],
];

const ChannelConfigurationHeaderData = ["Channel Configuration by Current", ""];
const ChannelConfigurationRowData = [
  ["Type-B", "60A, 56 Channel, 70A 56 Channel"],
  ["100A", "28 Channel 14 Channel"],
  ["200A", "14 Channel 7 Channel"],
  ["300A", "7 Channel 7 Channel"],
  ["400A", "7 Channel"],
  ["Type-B", "60A 28 Channel(TBD)"],
  ["(0V Discharge)", "100A 14 Channel(TBD)"],
  ["Under Developing", "200A, 7 Channel(TBD)"]
];

const EnvironmentConditionHeaderData = ["Environment Condition for Operating", ""];
const EnvironmentConditionRowData = [
  ["Temperature", "0 ~ 50°C"],
  ["Humidity", "20 ~ 80%"],
];

const AlarmHeadrData = ["Alarm", ""];
const AlarmRowData = [
  ["Power Fail", " GPS / UPS"],
  ["FAN", "FAN Fail or Low Speed"],
  ["Button", "Power/Emergency"],
  ["Visual Alarm", "Red / Yellow"],
  ["Audible Alarm", "Buzzer"]
];

const MechanicalSpecificationHeaderData = ["Mechanical Specification", ""];
const MechanicalSpecificationRowData = [
  ["Dimension", "389mm(H) X 111mm(L) X 609mm(D)"],
  ["Plug-In Type", "All Boards"]
]


const Telematics = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Aiverse Formation System </HeaderTitle>
        
        <div style={{height: '60px'}}></div>

        <HeaderContentSmall>
          Aiverse사는 2차전지(리튬이온 배터리) 제조 공정에서 필수적인 Formation 장비를 전문적으로 설계, 제작, 공급하는 선도 기업입니다. <br></br>
          Formation 장비는 배터리 셀 제조 과정에서 전기적 충방전 과정을 통해 셀의 성능을 극대화하고 초기 불량을 제거하는 데 사용됩니다. <br></br>
          Aiverse사의 Formation 장비는 고효율, 고정밀, 안정성을 자랑합니다. 2022년 설립 이래, 지속적인 기술 개발과 품질 혁신을 통해 2차전지 산업에서 신뢰받는 파트너로 자리매김해 왔습니다.
        </HeaderContentSmall>

        <div style={{height: 50}} />

        <FullImage Item={AibusImage}/>

        <SubHeading>주요 제품 및 서비스</SubHeading>

        <HeaderContentSmall>
          Aiverse의 Formation System은 배터리 성능 및 기타 특성 감시 확인에 최적화되어 있으며, <br></br>
          Li-Ion 배터리를 비롯한 다양한 종류의 배터리 타입에 충/방전 테스트 대응이 가능합니다. 이 시스템은 에너지 효율적인 구조를 갖추고 있으며, 다음과 같은 주요 특징을 자랑합니다
        </HeaderContentSmall>


        <GoodixTableBox data={GoodixTableItem01} />

        <div style={{height: 50}}/>

        <SubHeading>유지보수 및 관리</SubHeading>
        <HeaderContentSmall>
          Aiverse의 시스템 구조는 Shelf & Unit 타입의 Plug-In Type으로 구성되어 있어 사용자가 용이하게 유지보수 관리를 할 수 있는 장점을 가지고 있습니다.<br></br>
          이러한 설계는 시스템의 가동 시간을 최대화하고 유지보수 비용을 절감하는 데 기여합니다. <br></br> <br></br>
          Aiverse는 지속적인 기술 혁신과 품질 개선을 통해 고객에게 최상의 솔루션을 제공하며, 2차전지 산업에서 신뢰받는 파트너로서의 위치를 확고히 하고 있습니다.
        </HeaderContentSmall>

        <div style={{height: 30}}/>

        <SubHeading>(<>
          New Form-factor Shape, <br></br>
          Cost Sensitivity Market, High Density integration System
        </>)</SubHeading>
        <HeaderContentSmall>
          이차전지 필요성에 대한 기대치 증가로 인하여, 광범위한 Market 즉, Medical, Industrial, Transportation, Home Appliance, Consumer Products 등의 제품군들에 <br></br>
          Re-Chargeable Battery의 필요성은 점진적으로 증가하고 있으며, 이에 따른 High-Performance & Cost Effective한 formation System의 요구가 필요해지고 있습니다. <br></br> <br></br>
          Aiverse의 Formation System(PBCD-formation)은 Battery 성능 및 기타 특성 감시확인에 최적화되어 있으며, Li-Ion Battery를 비롯한 다양한 종류의 Battery type에 충/방전 Test 대응이 가능합니다. <br></br>
          Charging / Discharging 운용은 모두 Switching mode로 동작을 하여 에너지 효율적인 구조를 가지고 있으며, Discharging시에는 전력 회생을 하는 구조로 되어있어 Cost Effective 한 전력 구조를 가지고 있습니다. <br></br>
          또한 System 구조가 Shelf & Unit type의 Plug-In Type으로 구성이 되어있어 사용자가 용이하게 유지보수 관리를 할 수 있는 장점을 가지고 있습니다.
        </HeaderContentSmall>

        <div style={{height: 30}}/>

        <SubHeading>
          (<>Introduce PBCD-formation System & Advantage to<br></br>
          Apply Manufacture for Energy saving</>)
        </SubHeading>
        <HeaderContentSmall>
          PBCD-Formation System은 다양한 종류의 Re-Chargable Battery의 성능을 측정할 수 있는 장치로 다수의(7 ~ 56개) Battery 충/방전을 통하여 Battery의 성능, 수명, 용량 등을 측정하는 하는 기능을 가지고 있으며,<br></br>
          각각의 N개의 Battery에 대한 용량 계산 및 동작중의 상태 감시를 할 수 있다. <br></br> <br></br>
          단상 혹은 3상 AC전원을 받아서 Battery로 충전, 그리고 역으로 Battery 전원으로부터 전원 회생하여 Formation System으로서 AC 측으로 전원을 Re-Generation해 주는 방전 기능을 가지고 있어<br></br>
          Battery를 Test하는 과정에서 소모될 수 있는 전력을 효율적으로 관리하여 Energy 절약을 가능케 하는 Battery Formation 장치입니다.
        </HeaderContentSmall>

        <DivideLineArea>
          <div style={{height: 30}}/>
          <DivideLine />
          <div style={{height: 40}}/>
        </DivideLineArea>


        <SubHeading><>PBCD Formation 5VxxxA Systems <span style={{fontWeight: 500, fontSize: SIZE.LARGE}}>
          (Key Feature Overview)</span></></SubHeading>


        <TableContainer>
          
          <AiverseTable HeaderData={CapacitiveHeaderData} RowData={CapacitiveRowData}/>

          <AiverseTable HeaderData={PerformanceHeaderData} RowData={PerformanceRowData}/>

          <AiverseTable HeaderData={ChannelConfigurationHeaderData} RowData={ChannelConfigurationRowData}/>

          <AiverseTable HeaderData={EnvironmentConditionHeaderData} RowData={EnvironmentConditionRowData}/>

          <AiverseTable HeaderData={AlarmHeadrData} RowData={AlarmRowData}/>

          <AiverseTable HeaderData={MechanicalSpecificationHeaderData} RowData={MechanicalSpecificationRowData}/>
        
        </TableContainer>


        <HeaderContentSmall>
          Best-In-Class Performance & Cost-Effective formation Solutions for various battery Cell <br></br>
          다양한 Form과 다양한 Current별 Rechargable Battery의 대응이 가능하며, 우수한 성능감시 및 Board Plug-In Type설계로 <br></br>
          손쉬운 유지보수 관리를 통하여 최적화된 Factory Line 설계가 가능합니다. 
        </HeaderContentSmall>

      </HeaderContainer>
    </>
  )
}

const DivideLineArea = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 1px) and (max-width: 800px) {
    display: none;
  }
`

const TableContainer = styled.div`
  width: auto;
  height: auto;

  @media (min-width: 1px) and (max-width: 800px) {
    display: none;
  }
`

export default Telematics