import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import FullImage from '../../components/inner/venderProduct/FullImage'

import Mars01 from '../../assets/technologies/Mars1.png'
import GoodixTableBox from '../../components/inner/venderProduct/GoodixTableBox'
import COLOR from '../../global/Color'


const GoodixTableItem01 = [
  {
    title: 'MR6760A-128M',
    text: [(<>
        MR6760A-128M은 최대 8Mp CMOS 센서를 연결할 수 있는 고성능 PWiFi 기저대역 컨트롤러이며 <br></br>
        이미지 해상도와 진폭이 720p@25fps H.264 비디오 압축 엔진이 내장되어 있습니다. <br></br>
        BT656 인터페이스를 통해 AHD / TVI 720p 아날로그 HD 입력 인터페이스를 지원할 수 있습니다
    </>)]
  },
  {
    title: 'MR6768B-216M',
    text: [(<>
        디스플레이 화면이 있는 무선 쿼드 720p 비디오 수신기 MR6768B-216M은 H.264 압축 해제 엔진이 내장되어 있고 이미지 해상도와 이미지 비율이 720p@30fps 고성능 PWiFi 베이스밴드 컨트롤러입니다. <br></br>
        4채널 카메라 이미지 디스플레이를 동시에 지원할 수 있고, 최대 1024×768의 디스플레이 해상도를 지원, TFT LCD/LCM 컨트롤러가 있으며,<br></br> sRGB, RGB888, CCIR656 및 BT1120(HDMI) 및 기타 디스플레이 인터페이스는 물론 SD 카드, 그래픽 OSD, SPI 플래시, 3채널 ADC 및 기타 제어 인터페이스와 같은 기타 인터페이스를 지원할 수 있습니다.

    </>)]
  },
  {
    title: 'MR6761B-128M',
    text: [(<>
        AHD/TVI 인터페이스가 있는 무선 쿼드 720p 수신기 MR6761B-128M은 H.264 비디오 압축 해제 엔진이 내장되어 있고 이미지 해상도와 이미지 비율이 720p@30fps 고성능 PWiFi 베이스밴드 컨트롤러입니다.<br></br>
        최대 4개의 카메라 이미지를 동시에 지원하며 BT720 포트를 통해 AHD/TVI 656p 아날로그 HD 출력을 지원하여 DVR 또는 모니터에 연결할 수 있습니다.

    </>)]
  },
  {
    title: 'MR6775A-128M',
    text: [(<>
        무선 720p 후방 카메라 MR6775A-128M은 최대 8Mp CMOS 센서를 연결할 수 있는 고성능 PWiFi 기저대역 컨트롤러이며 이미지 해상도와 진폭 비율이 720p@25fps MPEG4 이미지 압축 엔진이 내장되어 있습니다.
    </>)]
  },
  {
    title: 'MR6776A-128M',
    text: [(<>
        화면이 있는 비디오 수신기를 위한 무선 4채널 720p MIPI 인터페이스 MR6776A-128M은 H.264 압축 해제 엔진과 720p@30fps 해상도가 내장된 고성능 PWiFi 베이스밴드 컨트롤러입니다. <br></br>
        4채널 카메라 이미지 디스플레이를 동시에 지원하고 최대 MIPI 720×1280의 디스플레이 해상도를 지원하며 90도의 디스플레이 회전 기능을 지원하고 수직 디스플레이를 가로 디스플레이로 사용할 수 있습니다. <br></br>
        SD 카드 비디오 녹화 기능을 지원합니다. 

    </>)]
  },
]

const Medical = () => {
  return (
    <HeaderContainer>
      <HeaderTitle>Mars</HeaderTitle>
      <div style={{height: '60px'}}/>
      <HeaderContentSmall>
        Mars사 <a href="https://www.mars-semi.com.tw/en" target="_blank" style={{color: COLOR.BLUE}}>(mars-semi.com.tw)</a>는 대만에 본사를 둔 무선 오디오 및 비디오 칩 전문 기업으로, 사물 인터넷(AIoT)의 부상에 대응하여 고객 네트워크, 모바일 앱 및 클라우드 애플리케이션을 위한 전반적인 솔루션을 제공합니다. <br></br>
        Mars사는 클라우드 시스템 통합 애플리케이션 서비스를 통해 전문 시장 부문에서 원스톱 무선 클라우드 시스템 서비스(SaaS) 소프트웨어 및 하드웨어 통합 서비스 제공업체로 성장하였습니다.
      </HeaderContentSmall>

      <FullImage Item={Mars01}/>

      <SubHeading>무선 오디오 및 비디오 칩 기술</SubHeading>
      <HeaderContentSmall>
        Mars사의 무선 오디오 및 비디오 칩은 디지털 무선(RF) 및 개인 WiFi 프로토콜(pWiFi)과 같은 고성능, 장거리 무선 기술을 통합하고 있으며,<br></br>
        MJPEG/MPEG4/H.264와 같은 고급 이미지 압축 및 압축 해제 기술을 포함하고 있습니다.<br></br>
        이를 통해 고객에게 최고 품질의 무선 오디오 및 비디오 솔루션을 제공합니다. <br></br>
        Mars사의 숙련된 소프트웨어 엔지니어 팀은 이러한 기술을 활용하여 전 세계적으로 판매되는 감시 카메라, 차량 시스템, 베이비 모니터 등의 다양한 제품 응용 프로그램을 개발해왔습니다.
      </HeaderContentSmall>

      <div style={{height: 50}} />

      <SubHeading>클라우드 서비스 및 시스템 통합</SubHeading>
      <HeaderContentSmall>
        Mars사는 순수 소프트웨어 서비스뿐만 아니라 자체 개발한 칩 장비를 통해 고객에게 소프트웨어와 하드웨어를 통합한 솔루션을 제공합니다. <br></br>
        이러한 통합 솔루션은 산업용 사물 인터넷(IIoT), 운송, 농업, 수자원 관리 등 다양한 분야에서 사용됩니다.<br></br>
        Mars사는 고객의 요구에 맞춘 맞춤형 서비스를 처음부터 끝까지 제공하며, 고객이 가능한 한 최단 시간에 제품을 시장에 출시할 수 있도록 지원합니다.
      </HeaderContentSmall>

      <div style={{height: 50}} />

      <SubHeading>사명 및 비전</SubHeading>
      <HeaderContentSmall>
        Mars사의 사명은 고객에게 실시간 기술 지원과 전문 기술 지원을 제공하여 고객이 시장에서 성공을 거둘 수 있도록 돕는 것입니다. <br></br>
        Mars사는 완벽한 솔루션을 제공함으로써 고객과 윈-윈 상황을 만들어 나가고 있습니다.
      </HeaderContentSmall>

      <div style={{height: 50}} />

      <SubHeading>Chip Introduction</SubHeading>
      <GoodixTableBox data={GoodixTableItem01} />

      <div style={{height: 50}} />

    </HeaderContainer>
  )
}

export default Medical