import React from 'react'
import styled from 'styled-components';

const TitleTextCard = ({ Title, Text}) => {
  return (
    <OuterContainer>
      <TextContainer>
        <TitleText>{Title}</TitleText>
        <DescriptionText>{Text}</DescriptionText>
      </TextContainer>
    </OuterContainer>
  );
}

const OuterContainer = styled.div`
  width: 30%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 10px;

  /* 450px 이상 800px 미만 */
    @media (min-width: 1px) and (max-width: 1400px) {
      align-items: flex-start;
      width: 100%;
  }
`;

const TextContainer = styled.div`
  width: 90%;
  height: 50%;
  display: flex;
  flex-direction: column;

  /* 450px 이상 800px 미만 */
  @media (min-width: 1px) and (max-width: 1400px) {
    padding-bottom: 50px;
  }
`;

const TitleText = styled.p`
  font-size: ${props => props.theme.SIZE.LARGE};
  font-weight: 600;
`;

const DescriptionText = styled.span`
  line-height: 1.7;
`;


export default TitleTextCard