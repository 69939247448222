import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import styled from 'styled-components'
import Portron1 from '../../assets/technologies/Portron1.jpg';
import Portron2 from '../../assets/technologies/Portron2.jpg';
import Portron3 from '../../assets/technologies/Portron3.jpg';

import Potron01 from '../../assets/technologies/PotronImage01.png'
import Potron02 from '../../assets/technologies/PotronImage02.png'
import Potron03 from '../../assets/technologies/PotronImage03.png'

import ImageTitleTextCard from '../../components/inner/technologies/ImageTitleTextCard'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import COLOR from '../../global/Color'

const BlackBox = () => {

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Potron</HeaderTitle>
        <div style={{height: '60px'}}/>
        <HeaderContentSmall>
          Potron Technology Co., Ltd. <a href="https://www.potrontec.com" target="_blank" style={{color: COLOR.BLUE}}>(https://www.potrontec.com)</a>는 2017년 8월에 설립되어 중국 심천에 본사를 두고 있는 회사로, <br />
          고속 광 트랜시버 및 부품의 연구 개발과 제조를 전문으로 하고 있습니다. Potron은 주로 다음과 같은 세 가지 주요 응용 분야에서 차세대 AI 광통신 네트워크에 필수적인 제품을 제공합니다
        </HeaderContentSmall>

        <ImageTitleTextCardContainer>
          <ImageTitleTextCard Image={Portron1} Title={(<>Revolutionizing Home Connectivity <br></br> 10G PON for FTTH Networks</>)} />
          <ImageTitleTextCard Image={Portron2} Title={(<>25G CPRI Enabling 5G Wireless Communication <br></br></>)} />
          <ImageTitleTextCard Image={Portron3} Title={(<>High-Speed Data Solutions for Data Centers <br></br> 100G, 200G, 400G</>)} />
        </ImageTitleTextCardContainer>

        <SubHeading>Potron 연구 개발팀</SubHeading>
        <HeaderContentSmall>
          Potron의 연구 개발 팀은 20년 이상의 경험을 바탕으로 고속 광 트랜시버 및 구성 요소를 설계하고 개발하는 데 전념하고 있습니다. <br></br>
          이러한 오랜 경험과 전문성 덕분에 Potron은 업계에서 최고 수준의 데이터 속도인 최대 400Gb/s를 달성했으며, 최대 120km의 전송 거리를 자랑하는 제품을 제공합니다. <br></br>
          Potron의 기술적 역량은 단순히 제품 성능에 그치지 않고, 고객의 다양한 요구 사항을 충족시키기 위한 빠르고 유연한 대응력으로도 입증되고 있습니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          이러한 강력한 기술적 배경과 고객 중심의 접근 방식을 통해 Potron은 고객으로부터 높은 신뢰와 인정을 받고 있으며, 고객과의 협력을 통해 부가가치가 높은 고급 제품을 공동으로 개발하고 있습니다. <br></br>
          Potron은 지속적인 혁신과 품질 향상을 통해 광통신 산업의 발전에 기여하고 있으며, 글로벌 시장에서 경쟁력을 유지하고 확대해 나가고 있습니다.
        </HeaderContentSmall>

        <ImageTitleTextCardContainer>
          <ImageTitleTextCard Image={Potron01} Title={(<>Telecom / Datacom Transceiver<br></br>5G Wireless / Legacy series</>)} />
          <ImageTitleTextCard Image={Potron02} Title={(<>PON Transceiver Series<br></br></>)} />
          <ImageTitleTextCard Image={Potron03} Title={(<>Data Center Transceiver Series</>)} />
        </ImageTitleTextCardContainer>

        <div style={{height: '50px'}} />

      </HeaderContainer>
    </>
  )
}


const ImageTitleTextCardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  padding-top: 60px;
  padding-bottom: 50px;


    /* 0px 이상 800px 미만 */
    @media (min-width: 1px) and (max-width: 1400px) {
    flex-direction: column;
  }

`


export default BlackBox