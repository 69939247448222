import React from 'react'
import COLOR from '../../../../global/Color'
import styled from 'styled-components';

const FifthInnerBox = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

const Container = styled.div`
  width: 55%;
  height: 70%;
  background-color: ${props => props.theme.COLOR.WHITE};
  opacity: 0.9;
  
  
  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
    width: 70%;
  }
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    width: 70%;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    width: 100%;
    height: 80%;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    width: 100%;
    height: 90%;
  }
`;

export default FifthInnerBox