import React from 'react'
import SIZE from '../../global/Size'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import DivideLine from '../../components/inner/venderProduct/DivideLine'
import LastHeaderContentSmall from '../../components/header/LastHeaderContentSmall'
import CenterAlignedContainer from '../../components/inner/venderProduct/CenterAlignedContainer'
import HorizontalMediaCard from '../../components/inner/venderProduct/HorizontalMediaCard'

import mobileDevice01 from '../../assets/vendorProduct/mobiledevice01.png';
import mobileDevice02 from '../../assets/vendorProduct/mobiledevice02.png';
import Theme from '../../global/Theme'

const Omnivision = () => {

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>반도체 패키징</HeaderTitle>
        <HeaderContent>
        <span style={{fontWeight: 700}}>반도체 패키징</span>
          은 반도체 칩을 보호하고 외부와의 전기적 연결을 제공하며, <br></br>
          열 방출을 효과적으로 관리하기 위해 반도체 칩을 다양한 패키지 형태로 봉인하는 과정을 의미합니다. <br></br>
        </HeaderContent>

        <LastHeaderContentSmall>
          이 과정은 반도체 산업에서 매우 중요한 단계로, 칩의 성능과 신뢰성, 내구성에 직접적인 영향을 미칩니다. <br></br>
          반도체 패키징 기술의 발전은 더욱 미세한 공정 기술과 고성능 반도체 제품의 개발에 필수적이며, 이는 최종 소비자 제품의 품질 향상으로 이어집니다.
        </LastHeaderContentSmall>

      </HeaderContainer>

      <DivideLine />

      <CenterAlignedContainer>
        <span style={{fontWeight: 600, fontSize: SIZE.X1LARGE, paddingBottom: 25, textAlign: 'center' }}>반도체 패키징 시장</span>
        <span style={{textAlign: 'center', lineHeight: 1.5, fontSize: SIZE.MEDIUM2, paddingBottom: 50, paddingLeft: '12%', paddingRight: '12%'}}>
          반도체 패키징 시장은 최근 몇 년 동안 지속적으로 성장해왔으며, <br></br> 
          이는 주로 스마트폰, 태블릿, 웨어러블 기기, 전기차(EV), 사물인터넷(IoT) 등 다양한 전자기기의 수요 증가와 밀접하게 연관되어 있습니다.
        </span>

        <HorizontalMediaCard 
          imageSrc1={mobileDevice01}
          content1={(
            <>
              5G 기술의 상용화와 인공지능(AI) 및 데이터 센터의 급속한 성장 역시 <br></br>
              반도체 패키징 시장의 확장을 이끄는 주요 요인으로 작용하고 있습니다. <br></br>
              이러한 기술 혁신은 더 높은 데이터 처리 속도, 효율적인 전력 소비, <br></br>
              소형화된 설계 등을 가능하게 하여, 다양한 전자기기의 성능과 기능을 크게 향상시킵니다.
            </>
          )}
          imageSrc2={mobileDevice02}
          content2={(
            <>
              당사가 제공하는 주요 제품으로는 Liquid EMC, Molded Underfill, Polyimide 제품 등이 있습니다. <br></br>
              Liquid EMC는 우수한 밀봉성과 열 방출 특성을 제공하여 반도체 칩의 신뢰성을 높이며, <br></br>
              Molded Underfill은 패키지와 기판 간의 기계적 스트레스를 최소화하여 내구성을 향상시킵니다. <br></br>
              Polyimide 제품은 높은 열 저항성과 전기적 절연성을 제공하여 다양한 고온 환경에서도 <br></br>
              안정적인 성능을 유지할 수 있도록 지원합니다.
            </>
          )}
        />

      </CenterAlignedContainer>
    </>
  )
}

export default Omnivision