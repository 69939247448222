import React, { useState } from 'react'
import MainLogo from '../button/navigationBtn/MainLogo'
import ComponyBtn from '../button/navigationBtn/CompanyBtn'
import RecruitBtn from '../button/navigationBtn/RecruitBtn'
import TechnologiesBtn from '../button/navigationBtn/TechnologiesBtn'
import VenderProductBtn from '../button/navigationBtn/VenderProductBtn'
import ContactBtn from '../button/navigationBtn/ContactBtn'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import LogoImage from '../../assets/lacessLogo.png'

const MainNaviagation = ({children}) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

    return (
      <HeaderContainer>
        <MainLogo>
          <StyledLink to="/">
            <StyledlogoImage src={LogoImage}/>
          </StyledLink>
        </MainLogo>
        <ComponyBtn>
          <StyledLink className='animated-button' to="/company">Company</StyledLink>
        </ComponyBtn>
        <VenderProductBtn>
          <StyledLink className='animated-button' to="/bizArea">Biz Area</StyledLink>
        </VenderProductBtn>
        <TechnologiesBtn>
          <StyledLink className='animated-button' to="/vendor">Vendor & Product</StyledLink>
        </TechnologiesBtn>
        <ContactBtn>
          <StyledLink to="/contact">Contact</StyledLink>
        </ContactBtn>
        <HamburgerBtn onClick={toggleNav}>
          ☰
        </HamburgerBtn>
        <NavContainer $isOpen={isOpen}>
          <NavList>
            <NavItem to="/company">Company</NavItem>
            <NavItem to="/bizArea">Biz Area</NavItem>
            <NavItem to="/vendor">Vendor & Product</NavItem>
            <NavItem to="/contact">Contact</NavItem>
          </NavList>
        </NavContainer>
      </HeaderContainer>
    );
  }

  const HamburgerBtn = styled.div`
    position: absolute;
    right: 0;
    padding-right: 6%;
    font-size: xx-large;
    cursor: pointer;
    display: none;
    z-index: 999;
    color: green;

    /* 450px 이상 800px 미만 */
    @media (min-width: 0px) and (max-width: 799px) {
      display: inline-block;
    }
  `
  
  const HeaderContainer = styled.div`
    width: 100%;
    height: 6vh;
    background-color: ${props => props.theme.COLOR.WHITE};
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.COLOR.BLACK};
    font-size: ${props => props.theme.SIZE.LARGE};
    z-index: 999;

    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      justify-content: flex-start;
      align-items: center;
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      justify-content: flex-start;
      align-items: center;
    }
  `;

    const NavContainer = styled.div`
      position: fixed;
      top: 0;
      right: 0;
      width: 300px;
      height: 100%;
      background-color: #333;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: ${({ $isOpen }) => ($isOpen ? 'translateX(0)' : 'translateX(100%)')};
      transition: transform 0.3s ease-in-out;
      z-index: 99;
    `;

    const NavList = styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
    `;

    const NavItem = styled(Link)`
      color: #fff;
      text-decoration: none;
      font-size: 1.5rem;

    &:hover {
      color: #ddd;
    }
    `;
  
  const StyledLink = styled(Link)`
    color: ${props => props.theme.COLOR.BLACK};
    text-decoration: none;
    height: 100%;
    width: 100%;
    text-align: center;
    font-weight: 600;
    place-content: center;

    /* 1300px 이상 1500px 미만 */
    @media (min-width: 1300px) and (max-width: 1499px) {
      width: 80%;
    }

    /* 800px 이상 1300px 미만 */
    @media (min-width: 800px) and (max-width: 1299px) {
      width: 70%;
    }
  `;

  const StyledlogoImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: scale(0.7);

    /* 450px 이상 800px 미만 */
    @media (min-width: 800px) and (max-width: 1300px) {
      transform: scale(0.7);
    }

    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      transform: scale(0.6);
    }

    /* 450px 미만 */
    @media (max-width: 449px) {
      transform: scale(0.6);
    }
  `;

export default MainNaviagation