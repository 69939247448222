import React from 'react'
import styled from 'styled-components';

const FullImage = ({Item}) => {
  return (
    <StyledImage src={Item} />
  );
  }
  
  const StyledImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: cover;
    padding-bottom: 50px;

      /* 800px 이상 1300px 미만 */
      @media (min-width: 800px) and (max-width: 1299px) {
        display: none;
      }
      
      /* 450px 이상 800px 미만 */
      @media (min-width: 450px) and (max-width: 799px) {
        display: none;
      }
      
      /* 450px 미만 */
      @media (max-width: 449px) {
        display: none;
      }

  `;
  

export default FullImage