import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import FullImage from '../../components/inner/venderProduct/FullImage'
import SubHeading from '../../components/inner/venderProduct/SubHeading'

import GoodixImage01 from '../../assets/vendorProduct/GoodixImage01.png';

const Goodix = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>EV Battery</HeaderTitle>
        <HeaderContent>
          <span style={{fontWeight: 700}}>전기차(EV) 배터리</span>
          는 전기차의 핵심 부품으로, 전기 모터에 전력을 공급하는 역할을 담당합니다. <br></br>
          배터리의 성능은 전기차의 주행 거리, 충전 시간, 비용, 안전성 등 여러 중요한 요소에 직접적인 영향을 미치며, <br></br>
          이는 전기차의 전체적인 효율성과 신뢰성에 중요한 역할을 합니다. 
        </HeaderContent>

        <HeaderContentSmall>
          글로벌 전기차 판매량은 지속적으로 증가하고 있으며, 이는 배터리 수요의 급증으로 이어지고 있습니다. <br></br>
          이와 같은 성장세는 지속 가능한 미래를 위한 전기차 보급 확대와 맞물려 전기차 배터리 기술의 발전을 더욱 가속화하고 있습니다.
        </HeaderContentSmall>

        <FullImage Item={GoodixImage01}/>

        <SubHeading>Product & Solution</SubHeading>

        <HeaderContentSmall>
          당사는 전기차 배터리 시장의 이러한 요구에 부응하여, 고객사들이 더 저렴하면서도
          안정적인 성능을 구현할 수 있도록 다양한 2차전지 관련 소재 및 부품, 장비를 제공하고 있습니다. <br></br>
          특히, 당사의 주요 제품군에는 고성능 전해질, 전극 소재, 첨가제 등이 포함되며,
          이들 제품은 배터리의 에너지 밀도 향상, 수명 연장, 안전성 강화 등에 기여합니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          당사는 또한 배터리 제조 공정의 최적화를 위한 장비와 솔루션을 제공하여 고객사들이 생산 효율성을 극대화할 수 있도록 지원합니다. <br></br>
          이는 전기차 배터리의 대량 생산에 필수적인 요소로, 안정적이고 일관된 품질을 유지하면서도 생산 비용을 절감할 수 있게 합니다. <br></br>
          더 나아가, 당사는 고객 맞춤형 서비스와 기술 지원을 통해 배터리 설계와 제조 과정에서 발생할 수 있는 다양한 문제를 신속하게 해결하며, 고객의 요구를 충족시키기 위해 지속적으로 혁신하고 있습니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          결론적으로, 당사는 전기차 배터리 시장에서 핵심적인 역할을 수행하며, 고객사들이 보다 경쟁력 있는 제품을 개발할 수 있도록 전방위적으로 지원하고 있습니다. <br></br>
          이를 통해 우리는 지속 가능한 전기차 산업의 발전에 기여하고, 궁극적으로는 친환경 모빌리티의 보편화를 촉진하고자 합니다.
        </HeaderContentSmall>

        <div style={{height: '1%'}}></div>

      </HeaderContainer>

    </>
  )
}

export default Goodix