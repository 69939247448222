import React from 'react'
import styled from 'styled-components'

const ContactBtn = ({children}) => {
  return (
    <Container>
      <StyledButton>
        {children}
      </StyledButton>
    </Container>
  );
}

const Container = styled.div`
  width: 15%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled.button`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.COLOR.CLEAR};
  color: ${props => props.theme.COLOR.WHITE};
  border: 0;
  font-size: ${props => props.theme.SIZE.MEDIUM};
  cursor: pointer;
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

export default ContactBtn