import React from 'react'
import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import SubHeading from '../../components/inner/venderProduct/SubHeading'

import styled from 'styled-components'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'

import Heiman02 from '../../assets/technologies/Heiman02.jpg'
import Heiman03 from '../../assets/technologies/Heiman03.png'
import FullImage from '../../components/inner/venderProduct/FullImage'
import COLOR from '../../global/Color'
import SmallImage from '../../components/inner/venderProduct/SmallImage'
import DivideLine from '../../components/inner/venderProduct/DivideLine'

const Security = () => {
  return (
    <HeaderContainer>

      <HeaderTitle>Heiman</HeaderTitle>
      <div style={{height: '60px'}}/>
      <HeaderContentSmall>
        Shenzhen Haiman Technology Co., Ltd. (Shenzhen Heiman Technology Co., Ltd._ <a target="_blank" href="https://www.heimantech.com/" style={{color: COLOR.BLUE}}>深圳市海曼科技股份有限公司</a>)는 2005년에 설립된 회사로, <br></br>
        사물인터넷 (IoT) 센서 및 솔루션 분야에서 지능형 보안 감지, 지능형 화재 감지, 환경 모니터링, 가전 제품 제어 등 다양한 제품을 다루며, 전체 산업 체인의 연구개발(R&D) 및 생산 능력을 보유하고 있습니다. <br></br>
        Heiman사는 지능형 감지, 스마트 화재 예방, 스마트 홈, 스마트 부동산, 스마트 호텔 등 다양한 분야에 대한 사물인터넷 (IoT) 원스톱 종합 솔루션을 제공합니다.
      </HeaderContentSmall>

      <FullImage Item={Heiman02}/>

      <SubHeading>주요 기술 및 연계 플랫폼</SubHeading>
      <HeaderContentSmall>
        Heiman사의 Matter 시리즈 제품은 China Telecom Tianyi, China Mobile OneNet, China Unicom, Huawei HiLink, Alibaba Cloud 및 Tuya와 같은 플랫폼에 연결되어 있으며,<br></br>
        Alexa, Google Home, HomeKit, Samsung SmartThings 등의 생태계와 호환되어 브랜드 간 및 생태계 간 상호 연결을 실현합니다.
      </HeaderContentSmall>

      <DivideLineArea>
        <div style={{height: 50}}/>
        <DivideLine/>
        <div style={{height: 50}}/>
      </DivideLineArea>

      <SmallImage Item={Heiman03}/>

      <DivideLineArea>
        <DivideLine/>
        <div style={{height: 50}}/>
      </DivideLineArea>

      <div style={{height: 50}}/>

      <SubHeading>인증 및 특허</SubHeading>
      <HeaderContentSmall>
        Heiman사는 300개 이상의 국내외 제품 인증 및 특허를 획득한 IoT 센서 분야의 강자로 자리매김하고 있습니다.
      </HeaderContentSmall>

      <div style={{height: 50}}/>

      <SubHeading>서비스 및 비전</SubHeading>
      <HeaderContentSmall>
        Heiman사는 고객에게 최첨단 기술과 혁신적인 솔루션을 제공함으로써 스마트 기술의 발전을 이끌고 있습니다. <br></br>
        당사의 목표는 다양한 산업 분야에서 스마트 솔루션을 통해 고객의 삶의 질을 향상시키고, 더욱 안전하고 편리한 생활 환경을 조성하는 것입니다.
      </HeaderContentSmall>

      <div style={{height: 50}}/>

    </HeaderContainer>
  )
}

const DivideLineArea = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 1px) and (max-width: 800px) {
    display: none;
  }
`

export default Security