import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import DefaultContainer from '../../components/container/DefaultContainer'
import Header from '../../components/container/Header'
import MainNaviagation from '../../components/navigation/MainNaviagation'
import InnerNaviagation from '../../components/navigation/InnerNaviagation'
import Footer from '../../components/container/Footer'
import AboutContainer from '../../components/container/company/AboutContainer'
import HeaderTitle from '../../components/inner/HeaderTitle'

import backgroundImage from '../../assets/technologies/technologiesBackgroundImage.png'

import Eternal from './Eternal'
import Potron from './Potron'
import Mars from './Mars'
import Union from './Union'
import Heiman from './Heiman'
import Aibus from './Aiverse'

const Technologies = () => {

  const TechnologiesItem = ["Eternal", "Potron", "Mars", "Union Image", "Heiman", "Aiverse"];

    return (
        <DefaultContainer>
            <Header headerBackgroundImage={backgroundImage}>
                <MainNaviagation />
                    <HeaderTitle>Vendor & Product</HeaderTitle>
                <InnerNaviagation item={TechnologiesItem}/>
            </Header>

            <AboutContainer>
              <Routes>
                <Route path='/' element={<Navigate to="Eternal" replace={false}/> }/>
                <Route path='/Eternal' element={<Eternal/>} />
                <Route path='/Potron' element={<Potron/>} />
                <Route path='/Mars' element={<Mars/>} />
                <Route path='/Union Image' element={<Union/>} />
                <Route path='/Heiman' element={<Heiman/>} />
                <Route path='/Aiverse' element={<Aibus/>} />
              </Routes>
            </AboutContainer>
    
            <Footer />
    
        </DefaultContainer>
      )
}


export default Technologies