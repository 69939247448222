import React from 'react'
import COLOR from '../../global/Color'
import SIZE from '../../global/Size'
import styled from 'styled-components';

const HeaderTitle = ({children}) => {
  return (
    <CenteredContainer>
      {children}
    </CenteredContainer>
  );
  }
  
  const CenteredContainer = styled.div`
    position: absolute;
    color: ${props => props.theme.COLOR.WHITE};
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.theme.SIZE.X2LARGE};
    font-weight: 700;
    text-align: center;
    
    /* 450px 이상 800px 미만 */
    @media (min-width: 450px) and (max-width: 799px) {
      font-size: ${props => props.theme.SIZE.X1LARGE};
    }
    
    /* 450px 미만 */
    @media (max-width: 449px) {
      font-size: ${props => props.theme.SIZE.X1LARGE};
    }
  `;
  

export default HeaderTitle