import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import FullImage from '../../components/inner/venderProduct/FullImage'
import SubHeading from '../../components/inner/venderProduct/SubHeading'

import SierraWirelessImage01 from '../../assets/vendorProduct/sierraWirelessImage01.png'

const SierraWireless = () => {

  return (
  <>
    <HeaderContainer>
      <HeaderTitle>ICT Device & Network</HeaderTitle>
      <HeaderContent>
      <span style={{ fontWeight: 700 }}>ICT Device & Network</span> 통신 시장은
        스마트폰, 태블릿, 컴퓨터 등 다양한 디바이스와 이들을 연결하는 유무선 네트워크 인프라를 포함합니다. <br></br>
        특히, 디지털 전환, 사물인터넷(IoT), 5G 기술의 도입, 원격 근무의 증가 등이 이러한 성장을 주도하는 주요 요인으로 작용하고 있습니다.
      </HeaderContent>

      <HeaderContentSmall>
        디지털 전환의 가속화는 전 세계적으로 기업과 개인의 일상생활 방식을 근본적으로 변화시키고 있습니다. <br></br>
        이에 따라 ICT 디바이스와 네트워크 통신 인프라의 수요도 급증하고 있습니다.
      </HeaderContentSmall>

      <HeaderContentSmall>
        사물인터넷(IoT)은 다양한 기기와 시스템 간의 연결성을 강화하여 새로운 서비스와 비즈니스 모델을 창출하고 있으며, <br></br>
        5G 기술의 확산은 초고속 데이터 전송과 저지연 통신을 가능하게 하여 더욱 정교하고 실시간에 가까운 애플리케이션을 지원하고 있습니다. <br></br>
        또한, 엣지 컴퓨팅과 인공지능(AI)의 발전은 데이터 처리와 분석의 효율성을 극대화하여, 네트워크 통신 시장의 성장을 한층 더 촉진하고 있습니다.
      </HeaderContentSmall>

      <FullImage Item={SierraWirelessImage01}/>

      <SubHeading>
        Product & Solution
      </SubHeading>

      <HeaderContentSmall>
        당사는 정보통신기술(Information and Communication Technology) 기기와 네트워크 시스템을 구축하고, 운영하는 데 필요한 다양한 하드웨어 부품과 모듈을 국내 유수의 관련 선도 기업들에게 소개합니다. <br></br>
        또한 기업 맞춤형 솔루션을 제공함으로써, 고객사들이 기술 혁신을 선도하고 시장에서의 경쟁력을 유지할 수 있도록 전방위적으로 지원하고 있습니다.
      </HeaderContentSmall>
      
      <HeaderContentSmall>
        당사의 주요 제품군에는 고성능 반도체 칩, 네트워크 모듈, 고속 인터페이스 부품 등이 포함되며, 이들 제품은 네트워크 인프라의 성능을 향상시키고, 안정성을 보장하며, 비용 효율적인 운영을 가능하게 합니다.
      </HeaderContentSmall>
      
    </HeaderContainer>

    <div style={{height: '50px'}} />
  </>
  )
}

export default SierraWireless