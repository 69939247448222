import React from 'react'
import styled from 'styled-components';

const SmallImage = ({ Item }) => {
    return (
        <Container>
            <StyledImage src={Item} />
        </Container>
      );
      }

      const Container = styled.div`
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      `
      
      const StyledImage = styled.img`
        width: 60%;
        height: auto;
        object-fit: contain;
        object-position: cover;
        padding-bottom: 50px;
    
          /* 800px 이상 1300px 미만 */
          @media (min-width: 800px) and (max-width: 1299px) {
            width: 100%;
          }
          
          /* 450px 이상 800px 미만 */
          @media (min-width: 450px) and (max-width: 799px) {
            display: none;
          }
          
          /* 450px 미만 */
          @media (max-width: 449px) {
            display: none;
          }
    
      `;
      

export default SmallImage