import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import GoodixTableBox from '../../components/inner/venderProduct/GoodixTableBox'
import SubHeading from '../../components/inner/venderProduct/SubHeading'
import COLOR from '../../global/Color'

const Syncomm = () => {


  const GoodixTableItem01 = [
    {
      title: '비품 공급',
      text: [(<>일반 기업에서 필수적으로 필요로 하는 IT 및 가전 제품을 안정적으로 공급합니다. <br></br>
                노트북, 모니터, TV, 프로젝터, 공기청정기 등 다양한 비품을 제공하여 사무 환경을 개선하고, 업무 효율성을 높일 수 있도록 지원합니다. <br></br>
                당사의 안정적인 공급망을 통해 품질 높은 제품을 적시에 제공받을 수 있습니다.'</>)]
    },
    {
      title: '선물(사은품)',
      text: [(<>
        기업의 창립기념일이나 명절 등 중요한 행사에 맞춰 직원들에게 제공할 사은품을 제안합니다.<br></br>
        LG전자의 다양한 IT/가전 제품을 선물로 제공함으로써 직원들의 사기를 높이고, 기업의 이미지를 제고할 수 있습니다.<br></br>
        맞춤형 선물 패키지를 통해 각 기업의 특성과 필요에 맞춘 최적의 솔루션을 제공합니다.
      </>)]
    },
    {
      title: '복지몰',
      text: [(<>
        중소기업을 위한 복지몰에 다양한 제품을 공급합니다. <br></br>
        기업 전용 인터넷 복지몰을 지원하여, 직원들이 필요로 하는 다양한 IT/가전 제품을 편리하게 구매할 수 있도록 합니다. <br></br>
        이를 통해 직원 복지를 증진시키고, 기업의 복지 프로그램을 강화할 수 있습니다.
      </>)]
    },
    {
      title: '결합상품',
      text: [(<>
        학습지 및 보험업계 등과 협력하여 노트북, 패드 등 IT 기기와 결합된 상품을 제안합니다. <br></br>
        결합상품을 통해 고객사는 추가 가치를 제공받으며, 최종 소비자는 보다 합리적인 가격에 다양한 제품과 서비스를 이용할 수 있습니다. <br></br>
        이러한 결합상품은 마케팅 및 프로모션 전략의 일환으로 효과적으로 활용될 수 있습니다.
      </>)]
    },
    {
      title: 'Display 제품',
      text: [(<>
        기업체, 학교, 학원 등에 필요한 전자칠판, 사이니지 등의 디스플레이 제품을 공급합니다. <br></br>
        고해상도의 디스플레이 제품을 통해 보다 효율적인 커뮤니케이션과 교육 환경을 조성할 수 있으며, 다양한 사이즈와 기능을 갖춘 제품군을 통해 각 고객사의 필요에 맞춘 맞춤형 솔루션을 제공합니다.
      </>)]
    },
    {
      title: '요식업계용 제품',
      text: [(<>
        식당용 배송/서빙 로봇 및 튀김 자동 장비를 공급합니다. <br></br>
        요식업계를 위한 특화된 제품을 통해 운영 효율성을 극대화하고, 인건비 절감 및 서비스 품질 향상을 도모합니다. <br></br>
        최신 기술이 적용된 요식업계용 제품은 고객사의 경쟁력을 높이고, 혁신적인 비즈니스 모델을 구축하는 데 기여합니다.
      </>)]
    },
  ]

  return (
    <>
    <HeaderContainer>
        <HeaderTitle>LG전자 B2B</HeaderTitle>
        <HeaderContent>
          <span style={{fontWeight: 700}}>LG전자 B2B <a href="https://www.lge.co.kr/kr/business" target="_blank" style={{color: COLOR.BLUE}}>(https://www.lge.co.kr/kr/business)</a></span> <br></br>
          전문점 사업을 통해 고객사들에게 특화된 서비스를 제공하고 있습니다. <br></br>
          이 사업은 일반 소비자가 아닌 기업에 필요한 IT/가전 제품을 프로모션하며, <br></br>
          다양한 형태의 니즈에 맞춘 비즈니스 제안을 통해 고객사의 만족도를 극대화하고자 합니다.
        </HeaderContent>

        <SubHeading>Product & Solution</SubHeading>
        <GoodixTableBox data={GoodixTableItem01} />

      </HeaderContainer>
      <div style={{height: 50}}/>
    </>
  )
}


export default Syncomm