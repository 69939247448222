import React from 'react'
import styled from 'styled-components';

const ImageTitleTextCard = ({Image, Title, Text}) => {
    return (
        <OuterContainer>
          <StyledImage src={Image} />
          <TextContainer>
            <TitleText>{Title}</TitleText>
          </TextContainer>
        </OuterContainer>
      );
    }
    
    const OuterContainer = styled.div`
      width: 30%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-right: 10px;
    
      /* 450px 이상 800px 미만 */
        @media (min-width: 1px) and (max-width: 1400px) {
          align-items: flex-start;
          width: 100%;
      }
    `;
    
    const TextContainer = styled.div`
      width: 100%;
      height: 30%;
      display: flex;
      text-align: center;
      flex-direction: column;
    
      /* 450px 이상 800px 미만 */
      @media (min-width: 1px) and (max-width: 1400px) {
        padding-bottom: 50px;
      }
    `;

    const StyledImage = styled.img`
    width: 100%;
    height: 70%;
    object-fit: contain;
    `;
    
    const TitleText = styled.p`
      font-size: ${props => props.theme.SIZE.LARGE};
    `;
    
    const DescriptionText = styled.span`
      line-height: 1.7;
    `;
    
    

export default ImageTitleTextCard