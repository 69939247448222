import React from 'react'
import COLOR from '../../../../global/Color'
import SIZE from '../../../../global/Size'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const ThirdInnerItem = ({item, text, link}) => {
  return (
      <Container>
        <LinkButton to={'/bizArea/' + link}>
          <Image src={item} />
          <Text>{text}</Text>
        </LinkButton>
      </Container>
    );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.COLOR.MORECLEARLESS};

  &:hover {
    transform: scale(1.05); /* 약간 확대되는 효과 */
  }

`;

const LinkButton = styled(Link)`
  width: auto;
  height: 100%;
  text-decoration: none;
  background-color: ${props => props.theme.COLOR.CLEAR};
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;


  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    justify-content: center;
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    justify-content: center;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 25%;
  height: 35%;
  position: relative;
  left: 15%;
  object-fit: contain;

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    display: none;
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    display: none;
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
    display: none;
  }
`;

const Text = styled.div`
  width: 50%;
  position: relative;
  color: ${props => props.theme.COLOR.WHITE};
  text-align: left;
  left: 20%;
  font-size: ${props => props.theme.SIZE.LARGE};
  font-weight: 400;
  
  
  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
    font-size: ${props => props.theme.SIZE.MEDIUM2};
    text-align: center;
    left: 0;
  }
  
  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
    font-size: ${props => props.theme.SIZE.MEDIUM2};
    text-align: center;
    left: 0;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    font-size: ${props => props.theme.SIZE.SMALL};
    text-align: center;
    left: 0;
  }

`;

export default ThirdInnerItem
