import React from 'react'
import backgroundImage from '../../../assets/main/Rectangle2_2.png'
import styled from 'styled-components';

const SecondContainer = ({children}) => {
  return <Container $backgroundImage={backgroundImage}>{children}</Container>;
}

const Container = styled.div`
  width: 100%;
  height: 90vh;
  background-color: #ccc;
  background-image: url(${props => props.$backgroundImage});
  background-position: center;
  background-size: cover;

  /* 601px 이상 800px 미만 */
  @media (min-width: 601px) and (max-width: 799px) {
    height: 70vh;
  }

  /* 450px 이상 600px 미만 */
  @media (min-width: 450px) and (max-width: 600px) {
    height: 60vh;
  }
  
  /* 450px 미만 */
  @media (max-width: 449px) {
    height: 40vh;
  }
`;

export default SecondContainer