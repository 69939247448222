import { BrowserRouter, Navigate, Route, Router, Routes  } from 'react-router-dom';
import Main from './screen/Main';
import Company from './screen/company/Company';
import Vendor from './screen/vendorProduct/Vendor';
import Technologies from './screen/technologies/Technologies'
import { ThemeProvider } from 'styled-components';
import Theme from './global/Theme';
import Contact from './screen/contact/Contact';
import ScrollToTop from './api/ScrollToTop';

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Navigate to="/main" />} />
          <Route path='/main' element={<Main/>}/>
          <Route path='/company/*' element={<Company />} />
          <Route path='/bizArea/*' element={<Vendor />} />
          <Route path='/vendor/*' element={<Technologies />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
