import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContent from '../../components/header/HeaderContent'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import ContentContainer from '../../components/content/ContentContainer'
import ContentTitle from '../../components/content/ContentTitle'
import ContentBox from '../../components/content/ContentBox'
import InnerItem from '../../components/inner/company/InnerItem'

import backgroundImage from '../../assets/company/aboutbackgroundImage.png'

import Theme from '../../global/Theme'

const About = () => {

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>About Lacess</HeaderTitle>
        <HeaderContent>
          <span style={{color: Theme.COLOR.MAGORELLEBLUE, fontWeight: 700}}>(주)라세스</span>는 Global technology의 앞선 Trend를 Leading하는<br></br>
          반도체, EV battery, ICT Device Industry에서 주요 소재, 부품, 모듈, 장비를 공급하는<br></br>
          선도적인 <span style={{fontWeight: 700, color: Theme.COLOR.MAGORELLEBLUE}}>기술 지향 유통 회사</span>입니다.
        </HeaderContent>
        
        <HeaderContentSmall>
          2010년 설립 이후, 지금까지 국내외 유수의 Vendor와 상호 신뢰 및 협력을 바탕으로 <br></br>
          반도체, EV battery, ICT Device Industry 산업 분야 고객사들의 성장과 발전에 기여해 오고 있습니다.
        </HeaderContentSmall>

        <HeaderContentSmall>
          당사는 앞으로도 Global leading technology분야의 세계 주요 제조사들과 협력하여, <br></br>
          그들의 신뢰할 수 있는 파트너로서 최고의 품질과 서비스를 제공하여, 고객과 함께 성장하고 발전해 나가겠습니다.
        </HeaderContentSmall>

      </HeaderContainer>

      <ContentContainer backgroundImage={backgroundImage}>
        <ContentTitle>Why Lacess?</ContentTitle>
        <ContentBox>
          <div style={{width: '73%', display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', gap: 20}}>
            <InnerItem Title={'Leading Access for Customer’s Success'}
              Content1={'· 고객사의 요구를 정확히 이해합니다,'}
              Content2={'· 고객사와 맞는 서비스와 제품을 제공합니다.'}
              Content3={'· 고객사가 지속적인 성장을 이룰 수 있게 합니다.'}
            />
            <InnerItem Title={'Optimum Solution Provider '}
                Content1={'· 고객사의 특정 요구와 문제를 깊이 분석합니다.'}
                Content2={'· 고객사에 최적화된 솔루션을 개발하고 제공합니다.'}
                Content3={'· 고객사의 피드백을 지속적으로 반영하여 솔루션의 품질과 효과를 개선합니다.'}
            />
            <InnerItem Title={'Innovative Paradigm Shifter '}
              Content1={'· 기존의 방식을 뛰어넘어 새로운 기준을 세웁니다.'}
              Content2={'· 산업의 방향을 새롭게 정의할 수 있도록 고객사를 지원합니다.'}
              Content3={'· 지속가능한 혁신을 통해 고객 가치를 극대화하고, 새로운 시대를 열 수 있도록 합니다.'}
            />
          </div>
        </ContentBox>
      </ContentContainer>
    </>
  )
}
export default About