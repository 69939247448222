import React from 'react'

import HeaderContainer from '../../components/header/HeaderContainer'
import HeaderTitle from '../../components/header/HeaderTitle'
import HeaderContentSmall from '../../components/header/HeaderContentSmall'
import SubHeading from '../../components/inner/venderProduct/SubHeading'

import DivideLine from '../../components/inner/venderProduct/DivideLine'

import ImageTitleTextCard from '../../components/inner/technologies/TitleTextCard'
import styled from 'styled-components'
import COLOR from '../../global/Color'

import EternalImage from '../../assets/technologies/EternalImage02.png'
import SmallImage from '../../components/inner/venderProduct/SmallImage'


const Automotive = () => {
  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Eternal</HeaderTitle>

        <div style={{height: '60px'}}/>
        
        <HeaderContentSmall>
          대만 Eternal Materials Co., Ltd. <a href="https://www.eternal-group.com/EN/Home" target="_blank" style={{color: COLOR.BLUE}}>(長興材料工業股份有限公司 (eternal-group.com))</a>는 1964년에 설립된 화학 재료 및 제품을 전문으로 하는 대만의 선도적인 회사입니다. <br></br>
          다양한 산업 분야에 걸쳐 제품을 공급하며, 주로 전자 재료, 특수 화학 제품, 석유화학 제품 등에서 두각을 나타내고 있습니다.
        </HeaderContentSmall>
        <HeaderContentSmall>
          특히 반도체 산업에서 중요한 역할을 하는 다양한 재료를 생산하고 있습니다. <br></br>
          반도체 제조 공정에 필수적인 고성능 재료를 제공하며, 기술 혁신과 품질 개선에 중점을 두고 있습니다. Eternal의 주요 반도체 재료는 다음과 같습니다:
        </HeaderContentSmall>

        <div style={{height: 30}}/>
        <DivideLine />
        <div style={{height: 60}}/>

        <SmallImage Item={EternalImage}/>

        <DivideLineArea>
          <div style={{height: 30}}/>
          <DivideLine />
          <div style={{height: 60}}/>
        </DivideLineArea>

        <SubHeading>Molded Underfill (MUF)</SubHeading>

        <ImageTitleTextCardContainer>
          <ImageTitleTextCard Title={'개요'}
            Text={(<>
              Molded Underfill은 반도체 패키징에서 <br></br>
              칩과 기판 사이의 공간을 채워주는 재료입니다. <br></br><br></br>
              주로 반도체 칩의 기계적 스트레스를 완화하고, <br></br>
              열 사이클링으로 인한 신뢰성을 향상시키기 위해 사용됩니다.
            </>)}
          />
          <ImageTitleTextCard Title={'주요 기능'}
            Text={(<>
            <span style={{fontWeight: 600}}>스트레스 완화</span><br></br>
            반도체 칩과 기판 간의 열팽창 계수 차이로 발생하는<br></br>
            기계적 스트레스를 완화합니다. <br></br> <br></br>
            <span style={{fontWeight: 600}}>열 관리</span><br></br>열 전달을 개선하여 칩의 열 관리를 돕습니다. <br></br><br></br>
            <span style={{fontWeight: 600}}>보호 기능</span><br></br> 물리적 충격, 습기, 화학물질 등으로부터 칩을 보호합니다.
            </>)}
          />
          <ImageTitleTextCard Title={'응용 분야'}
            Text={(<>
              <span style={{fontWeight: 600}}>플립칩 패키징</span><br></br>
              반도체 칩을 기판에 뒤집어 부착하는 플립칩 패키징에서 주로 사용됩니다. <br></br> <br></br>
              <span style={{fontWeight: 600}}>칩 온 보드(COB)</span><br></br> 칩을 직접 기판에 부착하는 COB 기술에서도 사용됩니다.
            </>)}
          />
        </ImageTitleTextCardContainer>

        <SubHeading>Liquid EMC (Liquid Encapsulation Material Compound)</SubHeading>

        <ImageTitleTextCardContainer>
          <ImageTitleTextCard Title={'개요'}
            Text={(<>
              액상 EMC(Liquid Encapsulation Material Compound)는<br></br>
              반도체 칩을 보호하기 위해 사용되는 액체형 봉지 재료입니다.<br></br><br></br>
              주로 전자기기 내부의 반도체 소자를 보호하고<br></br>
              안정성을 향상시키기 위해 사용됩니다.
            </>)}
          />
          <ImageTitleTextCard Title={'주요 기능'}
            Text={(<>
            <span style={{fontWeight: 600}}>전기적 절연</span><br></br>
            전기적 절연을 제공하여 소자 간의 전기적 간섭을 방지합니다.<br></br><br></br>
            <span style={{fontWeight: 600}}>열 관리</span><br></br>열 전도성을 개선하여 소자의 열 방출을 돕습니다. <br></br><br></br>
            <span style={{fontWeight: 600}}>보호 기능</span><br></br> 물리적 손상, 습기, 화학물질로부터 반도체 소자를 보호합니다.
            </>)}
          />
          <ImageTitleTextCard Title={'응용 분야'}
            Text={(<>
              <span style={{fontWeight: 600}}>반도체 패키징</span><br></br>
                다양한 반도체 패키징 기술에서 사용됩니다. <br></br> <br></br>
              <span style={{fontWeight: 600}}>전자기기</span><br></br>스마트폰, 태블릿, 컴퓨터 등 전자기기 내부의 반도체 소자 보호에 사용됩니다.
            </>)}
          />
        </ImageTitleTextCardContainer>

        <SubHeading>Polyimide Dry Film Type</SubHeading>

        <ImageTitleTextCardContainer>
          <ImageTitleTextCard Title={'개요'}
            Text={(<>
              Polyimide Dry Film Type은 우수한 열적, 기계적, 화학적 특성을 가진 고성능 폴리머입니다.<br></br>
              높은 온도에서도 안정적인 특성을 유지하여 다양한 산업 분야에서 널리 사용됩니다.
            </>)}
          />
          <ImageTitleTextCard Title={'주요 기능'}
            Text={(<>
            <span style={{fontWeight: 600}}>내열성 </span><br></br>고온에서도 안정적인 물리적, 화학적 특성을 유지합니다.<br></br><br></br>
            <span style={{fontWeight: 600}}>기계적 강도</span><br></br>우수한 기계적 강도와 유연성을 제공합니다. <br></br><br></br>
            <span style={{fontWeight: 600}}>화학적 저항성</span><br></br> 다양한 화학물질에 대한 뛰어난 저항성을 가집니다. <br></br><br></br>
            <span style={{fontWeight: 600}}>전기적 절연성</span><br></br> 우수한 전기적 절연 특성을 가집니다.
            </>)}
          />
          <ImageTitleTextCard Title={'응용 분야'}
            Text={(<>
              <span style={{fontWeight: 600}}>전자산업</span><br></br>유연한 회로 기판, 반도체 제조 공정, 전기 절연 필름 등에서 사용됩니다. <br></br> <br></br>
              <span style={{fontWeight: 600}}>항공우주산업</span><br></br>고온 환경에서의 기계적 안정성과 경량화 특성으로 항공기 부품에 사용됩니다. <br></br> <br></br>
              <span style={{fontWeight: 600}}>의료산업</span><br></br>내열성과 화학적 저항성으로 의료 기기 및 장비에 사용됩니다. <br></br> <br></br>
              <span style={{fontWeight: 600}}>기타 산업</span><br></br>자동차, 에너지, 산업용 기계 등 다양한 분야에서 활용됩니다.
            </>)}
          />
        </ImageTitleTextCardContainer>
        
      </HeaderContainer>
    </>
  )
}

const ImageTitleTextCardContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 70px;

    /* 0px 이상 800px 미만 */
    @media (min-width: 1px) and (max-width: 1500px) {
    flex-direction: column;
  }
`

const DivideLineArea = styled.div`
  width: 100%;
  height: auto;

  @media (min-width: 1px) and (max-width: 800px) {
    display: none;
  }
`

export default Automotive