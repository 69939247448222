import React from 'react'
import { Navigate, Route, Routes, } from 'react-router-dom'

import DefaultContainer from '../../components/container/DefaultContainer'
import Header from '../../components/container/Header'
import MainNaviagation from '../../components/navigation/MainNaviagation'
import InnerNaviagation from '../../components/navigation/InnerNaviagation'
import Footer from '../../components/container/Footer'
import AboutContainer from '../../components/container/company/AboutContainer'
import HeaderTitle from '../../components/inner/HeaderTitle'

import backgroundImage from '../../assets/vendorProduct/VendorBackgroundImage.png'

import EVBattery from './EVBattery'
import SemiconductorPackaging from './SemiconductorPackaging'
import ICTDevice from './ICTDeviceNetworks'
import Syncomm from './Syncomm'

const Vendor = () => {

    const CompanyItem = ["반도체 패키징", "EV 배터리", "ICT Device & Network", "LG전자 B2B"];
    
    return (
        <DefaultContainer>
          <Header headerBackgroundImage={backgroundImage}>
              <MainNaviagation />
                  <HeaderTitle>Biz Area</HeaderTitle>
              <InnerNaviagation item={CompanyItem}/>
          </Header>
  
          <AboutContainer>
            <Routes>
              <Route path='/' element={<Navigate to="반도체 패키징" replace={false}/> }/>
              <Route path='/반도체 패키징' element={<SemiconductorPackaging /> }/>
              <Route path='/EV 배터리' element={<EVBattery /> }/>
              <Route path='/ICT Device & Network' element={<ICTDevice /> }/>
              <Route path='/LG전자 B2B' element={<Syncomm /> }/>
            </Routes>
          </AboutContainer>
  
          <Footer />
    
        </DefaultContainer>
      )
}


export default Vendor