import React from 'react'
import DefaultContainer from '../components/container/DefaultContainer'
import MainNavigation from '../components/navigation/MainNaviagation'
import MainContainer from '../components/container/main/MainContainer'
import MainInnerText from '../components/inner/main/MainInnerText'
import MainInnerBtn from '../components/inner/main/MainInnerBtn'
import SecondContainer from '../components/container/main/SecondContainer'
import FifthContainer from '../components/container/main/FifthContainer'
import Footer from '../components/container/Footer'
import SecondInnerTitle from '../components/inner/main/second/SecondInnerTitle'
import ThirdInnerBox from '../components/inner/main/third/ThirdInnerBox'
import ThirdInnerItem from '../components/inner/main/third/ThirdInnerItem'

// / Vendor & Product 이미지 임포트
import vendorIcon1 from '../assets/main/vendorIcon1.png'
import vendorIcon2 from '../assets/main/vendorIcon2.png'
import vendorIcon3 from '../assets/main/vendorIcon3.png'
import vendorIcon4 from '../assets/main/vendorIcon4.png'

// / Application 이미지 임포트
import FifthInnerBox from '../components/inner/main/fifth/FifthInnerBox'
import FifthInnerTitle from '../components/inner/main/fifth/FifthInnerTitle'
import FifthInnerItem from '../components/inner/main/fifth/FifthInnerItem'

// / Contact Us 이미지
import Adress from '../assets/main/ContactAdressIcon.png'
import Phone from '../assets/main/ContactPhoneIcon.png'
import Fax from '../assets/main/ContactFaxIcon.png'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const Main = () => {
  return (
    <DefaultContainer>
      
      {/* 첫 번째 칸 */}
      <MainContainer>
        <MainNavigation />
        {/* <MainInnerText>Leading Access Customer’s Success</MainInnerText> */}
        <MainInnerText>Leading Access For Customer’s Success</MainInnerText>
        <MainInnerBtn>About Lacess</MainInnerBtn>
      </MainContainer>
      
      {/* 두 번째 칸 */}
      <SecondContainer>
        <SecondInnerTitle>Biz Area</SecondInnerTitle>
        <ThirdInnerBox>
          <ThirdInnerItem item={vendorIcon1}  text={'Semiconductor Packaging'} link={'반도체 패키징'}/>
          <ThirdInnerItem item={vendorIcon2} text={'EV Battery'} link={'EV 배터리'}/>
          <ThirdInnerItem item={vendorIcon3} text={'ICT Device & Network'} link={'ICT Device & Network'}/>
          <ThirdInnerItem item={vendorIcon4} text={'LG Electronic B2B'} link={'LG전자 B2B'}/>
        </ThirdInnerBox>
        {/* <SecondInnerBox>
          <SecondInnerItem item={Omnivision} link={'omnivision'}/>
          <SecondInnerItem item={Goodix} link={'goodix'}/>
          <SecondInnerItem item={Sierra} link={'sierra wireless'}/>
          <SecondInnerItem item={Synic} link={'syncomm'}/>
          <SecondInnerItem item={Byd} link={'byd'}/>
          <SecondInnerItem item={Catl} link={'catl'}/>
        </SecondInnerBox> */}
      </SecondContainer>

      {/* 다섯 번째 칸 */}
      <FifthContainer>
        <FifthInnerBox>
          <FifthInnerTitle>Contact Us</FifthInnerTitle>
          <FifthInnerItem Icon={Adress} title={'Adress'}>경기도 성남시 분당구 판교로 255번길 9-22 우림 W-City 709호</FifthInnerItem>
          <FifthInnerItem Icon={Phone} title={'Phone'}>031-628-8605</FifthInnerItem>
          <FifthInnerItem Icon={Fax} title={'Fax'}>031-628-8601</FifthInnerItem>
        </FifthInnerBox>
      </FifthContainer>

      <Footer />
      
    </DefaultContainer>
  )
}

const LinkButton = styled(Link)`
  width: 100%;
  height: 100%;

  /* 1300px 이상 1500px 미만 */
  @media (min-width: 1300px) and (max-width: 1499px) {
  }

  /* 800px 이상 1300px 미만 */
  @media (min-width: 800px) and (max-width: 1299px) {
  }

  /* 450px 이상 800px 미만 */
  @media (min-width: 450px) and (max-width: 799px) {
  }

  /* 450px 미만 */
  @media (max-width: 449px) {
  }

`

export default Main